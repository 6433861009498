<template>
  <div class="rightBox">
    <div class="right">
      <div class="right-top">
        <div>
          <div class="inputBox">
            <el-select
              v-model="videoSelect"
              placeholder="请选择"
              popper-class="partySelect"
              @change="handleChangeVideo"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="top-l">
            <video :src="videoSrc" class="video" controls="controls">
              您的浏览器不支持 video 标签。
            </video>
            <!-- <VideoPlayer
            :options="videoOptions"
            class="videoS"
            :src="videoSrc"
            style="
              width: 100%;
              margin: 0 auto;
              padding-bottom: 0.5rem;
              object-fit: fill;
            "
          /> -->
          </div>
        </div>

        <div>
          <div class="top-r" v-show="fengcaiShow">
            <h5>党员风采</h5>
            <img
              src="@/assets/home/zhankai.png"
              alt=""
              class="imgs"
              @click="handleShow"
            />
            <div>
              <div class="party">
                <div class="yes">
                  <p class="txt">
                    <countTo :startVal='startVal' :endVal='partyNmuber.numberOfPartyMembers ? partyNmuber.numberOfPartyMembers:0' :duration='3000' style="font-weight: 700;font-size: .4rem;"></countTo>
                    <span>人</span>
                  </p>
                  <p>党员</p>
                </div>
                <div class="no">
                  <p class="txt txt1">
                    <countTo :startVal='startVal' :endVal='partyNmuber.numberOfProbationaryPartyMembers ? partyNmuber.numberOfProbationaryPartyMembers:0' :duration='3000' style="font-weight: 700;font-size: .4rem;"></countTo>
                    <span>人</span>
                  </p>
                  <p>预备党员</p>
                </div>
              </div>
              <div class="list">
                <h6>党员列表</h6>
                <ul class="scrollbar">
                  <li v-for="(item, index) in partyList" :key="index">
                    <div class="imgK">
                      <img :src="item.partyMemberImage" alt="" />
                    </div>
                    <div class="content">
                      <p>{{ item.partyMemberName }}</p>
                      <p class="p1">
                        {{ item.partyMemberIntroduction }}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            class="top-r"
            v-show="!fengcaiShow"
            style="height: 0.615rem"
            :style="{ backgroundImage: 'url(' + imgSrc + ')' }"
          >
            <h5>党员风采</h5>
            <img
              src="@/assets/home/zhankai.png"
              alt=""
              class="imgs"
              @click="handleShow"
              style="transform: rotate(180deg)"
            />
          </div>
        </div>
      </div>
      <div class="right-bottom">
        <h5>党建学习</h5>
        <div class="contentBox">
          <div class="lunbo">
            <div
              id="swiper12"
              class="swiper-container swiper"
              style="height: 2.625rem; width: 4.55rem"
            >
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item, i) in studyImgList"
                  :key="i"
                >
                  <div class="table-content">
                    <img :src="item.dataPath" alt="" />
                    <span class="tospan">{{ item.dataTitle }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ul class="scrollbar">
            <li
              v-for="(item, index) in studyList"
              :key="index"
              @click="handleLearn(item)"
            >
              <div class="img">
                <img :src="item.learningImage" alt="" />
              </div>
              <div class="pBox">
                <p class="p1">{{ item.learningTitle }}</p>
                <p class="p2" v-html="item.activities">{{ item.activities }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import VideoPlayer from "@/components/VideoPlayer/index.vue";
import countTo from "vue-count-to"
export default {
  components: {
    VideoPlayer,
    countTo
  },
  data() {
    return {
      partyNmuber: {
        numberOfPartyMembers: "",
        numberOfProbationaryPartyMembers: "",
      },
      imgSrc: require("../../../assets/home/titleBg.png"),
      fengcaiShow: true,
      videoOptions: {
        autoplay: true, //自动播放
        controls: true, //用户可以与之交互的控件
        loop: true, //视频一结束就重新开始
        muted: true, //默认情况下将使所有音频静音
        aspectRatio: "16:9", //显示比率
        fullscreen: {
          options: { navigationUI: "hide" },
        },
        sources: [
          {
            src: "",
            type: "video/mp4",
          },
        ],
      },
      videoSrc: "",
      partyList: [], //党员列表
      studyList: [], //党建学习
      options: [], // 视频列表
      value: "",
      videoSelect: "",
      studyImgList: [],
    };
  },
  computed: {},
  created() {
    this.getList();
    this.queryList();
    this.partyNumber();
    this.getDataManagement();
    this.getStudyManagement();
  },
  methods: {
    handleShow() {
      this.fengcaiShow = !this.fengcaiShow;
    },
    //党员风采
    async getList() {
      let res = await this.$api.partybuilding.partyMember();
      if (res.code == 200) {
        this.partyList = res.data;
      }
    },
    //党员学习
    async queryList() {
      let data = {};
      let res = await this.$api.partybuilding.queryPageDataByParam(data);
      if (res.code == 200) {
        this.studyList = res.data.dataList.map((it) => {
          if (it.activities) {
            it.activities = it.activities.replace(/<img.*?>/g, "");
          }
          return it;
        });
      }
    },
    //轮播图片

    //党员数量
    async partyNumber() {
      let data = {
        isNew: 1,
      };
      let res = await this.$api.partybuilding.partyNumber(data);
      if (res.code == 200) {
        this.partyNmuber = res.data.dataList[0];
      }
    },
    handleLearn(item) {
      this.$router.push({
        path: "/partyinfo",
        query: { learnId: item.learningId },
      });
    },
    async getDataManagement() {
      let data = {
        dataType: 1,
      };
      let res = await this.$api.partybuilding.getDataManagement(data);
      if (res.code == 200) {
        let arr = res.data.dataList;
        arr.sort((a, b) => {
          return a.dataSort - b.dataSort;
        });
        this.options = arr.map((item, idx) => {
          return {
            label: item.dataTitle,
            value: idx,
            path: item.dataPath,
          };
        });
        this.videoSelect = 0;
        this.videoSrc = this.options[0].path;
      }
    },
    async getStudyManagement() {
      let data = {
        dataType: 3,
      };
      let res = await this.$api.partybuilding.getDataManagement(data);
      if (res.code == 200) {
        let arr = res.data.dataList;
        arr.sort((a, b) => {
          return a.dataSort - b.dataSort;
        });
        this.studyImgList = arr;
      }
    },
    handleChangeVideo() {
      this.videoSrc = this.options[this.videoSelect].path;
    },
  },
  mounted() {
    setTimeout(function () {
      new Swiper("#swiper12", {
        direction: "horizontal",
        slidesPerView: 1,
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
      });
    }, 1000);
  },
};
</script>

<style lang="scss" scoped>
.rightBox {
  position: absolute;
  right: 0.35rem;
  top: 1rem;
  .right {
    position: relative;
    color: #fff;
    width: 17.625rem;
    height: 9.2rem;
    .right-top {
      display: flex;
      margin-bottom: 0.2rem;
      .top-l {
        position: relative;
        width: 11.95rem;
        // height: 51vh;
        height: 6.55rem;
        background-image: url("../../../assets/partybuilding/video.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        overflow: hidden;
        margin-right: 0.2rem;
        padding: 0.15rem;
        // padding-bottom: 0.5rem;

        .inputK {
          position: absolute;
          width: 2.1rem;
        }
        .video {
          width: 100%;
          height: 100%;
        }
      }
      .top-r {
        position: relative;
        top: 0.06rem;
        width: 5.4rem;
        height: 7.075rem;
        // height: 50.1vh;
        background-image: url("../../../assets/partybuilding/show.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        h5 {
          font-size: 0.3rem;
          font-family: "YouSheBiaoTiHei";
          padding-left: 0.55rem;
          padding-top: 0.12rem;
        }
        .imgs {
          position: absolute;
          right: 0.3rem;
          top: 0.3rem;
        }
      }
      .party {
        display: flex;
        margin-top: 0.55rem;
        justify-content: space-around;
        padding: 0 0.2rem;
        .yes {
          width: 2.325rem;
          height: 1rem;
          background-image: url("../../../assets/partybuilding/yes.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          padding-left: 0.9rem;
          padding-top: 0.18rem;
        }
        .no {
          width: 2.325rem;
          height: 1rem;
          background-image: url("../../../assets/partybuilding/no.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          padding-left: 0.9rem;
          padding-top: 0.18rem;
        }
        .txt {
          font-size: 0.3rem;
          color: #23b9ff;
          font-weight: 700;
        }
        .txt1 {
          color: #00ffe5;
        }
        p {
          color: #abdafe;
          span {
            font-size: 0.1rem;
          }
        }
      }
      .list {
        margin-top: 0.2rem;
        margin-bottom: 0.1rem;
        padding: 0 0 0 0.35rem;
        ul {
          width: 4.8rem;
          height: 4.1rem;
          overflow-y: auto;
        }
        li {
          display: flex;
          align-items: center;
          margin-bottom: 0.25rem;
        }
        h6 {
          font-weight: 700;
          font-size: 0.2rem;
          margin-bottom: 0.2rem;
        }
        .imgK {
          width: 0.8rem;
          height: 0.8rem;
          border: 1px solid #fff;
          margin-right: 0.1rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .content {
          width: calc(100% - 0.9rem);
        }
        p {
          font-size: 0.225rem;
        }
        .p1 {
          font-size: 0.1725rem;
          color: #abdafe;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
    .right-bottom {
      width: 17.625rem;
      height: 3.9125rem;
      // height: 28vh;
      background-image: url("../../../assets/partybuilding/study.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      align-items: center;
      h5 {
        font-size: 0.3rem;
        font-family: "YouSheBiaoTiHei";
        padding-left: 0.5rem;
        padding-top: 0.05rem;
      }
      .contentBox {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding-top: 0.5rem;
        padding-left: 0.15rem;
      }
      .lunbo {
        position: relative;
        background-image: url("../../../assets/partybuilding/imgk.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        overflow: hidden;
        width: 4.55rem;
        height: 2.6rem;
        .table-content {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 2.6rem;
            object-fit: cover;
          }
        }
      }
      .tospan {
        position: absolute;
        left: 28px;
        bottom: 14px;
        color: white;
        font-size: 14px;
        font-weight: bold;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        width: 12.5rem;
        height: 2.625rem;
        overflow-y: auto;
        margin-right: 0.1rem;
        li {
          display: flex;
          width: 50%;
          align-items: center;
          margin-bottom: 0.125rem;
          padding-top: 0.03rem;
          cursor: pointer;
          p {
            width: 4rem;
          }
          .p1 {
            font-weight: 700;
            color: #fff;
            font-size: 0.2rem;
            margin-bottom: 0.05rem;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            transition: all 0.3s;
            &:hover {
              color: #abdafe;
            }
          }
          .p2 {
            font-size: 0.12rem;
            color: #abdafe;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }
        .img {
          width: 2rem;
          height: 1.2rem;
          margin-right: 0.1rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
<style lang='scss'>
.top-l {
  .video-js {
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    height: auto;
    width: auto;
  }
  .videoS {
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    height: auto;
    width: auto;
    .video-js .vjs-tech {
      height: 105%;
      object-fit: fill;
    }
  }
}
.el-popper[x-placement^="bottom"] .popper__arrow {
  display: none !important;
}
.inputBox {
  width: 2.05rem;
  height: 0.6rem;
  line-height: 0.4rem;
  border-radius: 0.25rem;
  background-size: 100% 100%;
  z-index: 100;
  background-repeat: no-repeat;
  p {
    padding-left: 0.2rem;
    font-weight: 700;
    span {
      padding-left: 0.4rem;
    }
  }
  .inputs {
    position: absolute;
    width: 2.05rem;
  }
  .inputBox {
    position: absolute;
    left: 0;
    top: 0;
    .el-input__inner {
      height: 0.6rem;
    }
  }
  .el-input__inner {
    padding: 0 0.15rem;
    border-radius: 0.2rem;
    background-color: transparent;
    background-image: url("../../../assets/partybuilding/inputBg.png");
    background-size: 100% 100%;
    border: 0;
    color: #fff;
    font-weight: 700;
  }
}
.partySelect {
  background-image: url("../../../assets/partybuilding/select.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: transparent;
  border: 0;
  .el-select-dropdown__item {
    color: #abdafe;
  }
  .el-select-dropdown {
  }
  .el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    background-image: url("../../../assets/partybuilding/selectBg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #fff;
    font-weight: 700;
    background-color: transparent;
  }
}

</style>