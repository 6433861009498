<template>
  <dv-full-screen-container class="bg">
    <Header></Header>
    <Left />
    <Right />
    <Footer></Footer>
  </dv-full-screen-container>
</template>

<script>
import Header from "@/components/Header/header.vue";
import Footer from "@/components/Footer/footer.vue";
import Left from "./left";
import Right from "./right";
export default {
  components: {
    Header,
    Footer,
    Left,
    Right,
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.bg {
  background-image: url("../../assets/video/bg.png");
  background-position: bottom center;
  background-size: 100% 100%;
}
</style>