<template>
  <div class="leftBox">
    <div>
      <div class="left" v-show="activityShow">
        <h5>党建活动</h5>
        <img
          src="@/assets/home/zhankai.png"
          alt=""
          class="imgs"
          @click="handleActivity"
        />
        <div class="contentBox">
          <div
            id="swiper7"
            class="swiper-container swiper"
            style="height: 2.625rem; width: 4.7rem"
          >
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, i) in imgList"
                :key="i"
              >
                <div class="table-content">
                  <img :src="item.dataPath" alt="" />
                </div>
              </div>
            </div>
          </div>
          <ul style="margin-top: 0.2rem">
            <li
              v-for="(item, index) in contentList"
              :key="index"
              @click="handleLi(item)"
            >
              <div class="imgK">
                <img :src="item.annexPath" alt="" />
              </div>
              <div class="content">
                <p class="tit">{{ item.activityName }}</p>
                <p class="p1">
                  {{ item.activityTheme }}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="left"
        v-show="!activityShow"
        style="height: 0.615rem"
        :style="{ backgroundImage: 'url(' + imgSrc + ')' }"
      >
        <h5>党建活动</h5>
        <img
          src="@/assets/home/zhankai.png"
          alt=""
          class="imgs"
          @click="handleActivity"
          style="transform: rotate(180deg)"
        />
      </div>
    </div>
  </div>
</template>

<script>
let swiperDiv = "";
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
export default {
  data() {
    return {
      imgSrc: require("../../../assets/home/titleBg.png"),
      activityShow: true,
      contentList: [],
      imgList: [
      ],
    };
  },
  computed: {},
  created() {
    this.getList();
    this.getDataManagement();
  },
  methods: {
    //党建活动
    async getList() {
      let data = {};
      let res = await this.$api.partybuilding.activityInfo(data);
      if (res.code == 200) {
        this.contentList = res.data;
      }
    },
    handleActivity() {
      this.activityShow = !this.activityShow;
    },
    handleLi(item) {
      this.$router.push({
        path: "/partyinfo",
        query: { activityId: item.activityId },
      });
    },
    async getDataManagement() {
      let data = {
        dataType: 2
      }
      let res = await this.$api.partybuilding.getDataManagement(data)
      if(res.code == 200) {
        let arr = res.data.dataList
        arr.sort((a,b) => {
          return a.dataSort - b.dataSort
        })
        this.imgList = arr
      }
    },
    //轮播图片
  },
  mounted() {
    setTimeout(function () {
      new Swiper("#swiper7", {
        direction: "horizontal",
        slidesPerView: 1,
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
      });
    }, 1000);
  },
};
</script>

<style lang="scss" scoped>
.leftBox {
  position: absolute;
  top: 1rem;
  left: 0.35rem;
  color: #fff;
  .left {
    width: 5.4rem;
    height: 11.3rem;
    // height: 80vh;
    background-image: url("../../../assets/partybuilding/activity.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    h5 {
      font-size: 0.3rem;
      font-family: "YouSheBiaoTiHei";
      padding-left: 0.55rem;
      padding-top: 0.12rem;
    }
    .imgs {
      position: absolute;
      right: 0.3rem;
      top: 0.3rem;
    }
    .contentBox {
      position: absolute;
      top: 0.8rem;
      padding-left: 0.3rem;
      padding-right: 0.3rem;
      height: 11.3rem;
      ul {
        width: 4.9rem;
        // height: calc(100vh - 4rem);
        height: 7.4rem;
        overflow-x: auto;
      }
      li {
        display: flex;
        align-items: center;
        margin-bottom: 0.18rem;
        cursor: pointer;
        p {
          transition: all 0.3s;
        }
        &:hover {
          .tit {
            color: #abdafe;
          }
        }
      }
      .imgK {
        width: 1rem;
        height: 1rem;
        border: 1px solid #fff;
        margin-right: 0.1rem;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .content {
        width: calc(100% - 1.1rem);
      }
      .table-content {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 2.625rem;
          object-fit: cover;
        }
      }
      p {
        font-size: 0.25rem;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .p1 {
        font-size: 0.2rem;
        color: #abdafe;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}
/* 滚动条样式 */
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 4px;
  height: 10px;
  //   background-color: #00d8ff;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px #08101d;
  // border-radius: 10px;
  background-color: #0b1933;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  // -webkit-box-shadow: inset 0 0 6px #152847;
  background-color: #00d8ff;
}
</style>