<template>
  <div>
  <video ref="videoPlayer"  class="video-js"></video>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    src: {
      type: String,
    }
  },
  data() {
    return {
      player: null,
    };
  },
  watch: {
    options(val) {
      this.initVideo()
    },
    src(val) {
      this.player.src(val)
    }
  },
  mounted() {
    this.initVideo()
  },
  methods: {
    initVideo() {
      this.player = this.$video(
        this.$refs.videoPlayer,
        this.options,
        function onPlayerReady() {
          console.log("onPlayerReady", this);
        }
      );
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>
<style lang="scss" scoped>
.video {
  width: 100%;
  height: 100%;
}
</style>
